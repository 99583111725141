import { i18n } from '@/vueI18n';
import IdField from '@/shared/fields/id-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import ImagesField from '@/shared/fields/images-field';

// import i18n from './vueI18n'

function label(name) {
  return i18n(`entities.plan.fields.${name}`);
}
// function enumeratorLabel(name, value) {
//   return i18n(`entities.plan.enumerators.${name}.${value}`);
// }

const fields = {
  id: new IdField('id', label('id')),
  branchId: new StringField('branchId', label('branchId'), {}),
  firstName: new StringField('firstName', label('firstName'), {}),
  lastName: new StringField('lastName', label('lastName'), {}),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {}),
  email: new StringField('email', label('email')),
  avatar: new ImagesField(
    'avatar',
    label('avatar'),
    '/saryahCompany/admin/avatar', {
      max: 1
    },
  ),
};

export class AdminsModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
