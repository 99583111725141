//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import { getLanguageCode } from '@/i18n';

import { FormSchema } from '@/shared/form/form-schema';
import { AdminsModel } from '@/modules/admins/admins-model';

const { fields } = AdminsModel;
const formSchema = new FormSchema([
  fields.id,
  fields.branchId,
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.email,
]);

export default {
  name: 'app-payment-wallet-modal',

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    // id: {
    //   type: String,
    //   required: false,
    // },
    // values: {
    //     type: Array,
    //     required: true
    // }
  },

  data() {
    return {
      // language: getLanguageCode(),
      rules: formSchema.rules(),
      model: null,
      options: ['Makkah', 'Jeddah', 'Al Madina', 'Dammam'],
      amount: null,
    };
  },
  computed: {
    ...mapGetters({
      //   record: 'plan/form/record',
      //   findLoading: 'plan/form/findLoading',
      saveLoading: 'payment/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
      //   citiesRows: 'city/list/rows',
      //   loadingCities: 'city/list/loading',
      //   placesRows: 'place/list/rows',
      //   loadingPlaces: 'place/list/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isEditing() {
      return !!this.id;
    },
    fields() {
      return fields;
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    cities() {
      return this.citiesRows.map((item) => {
        return {
          value: item.id,
          label: item.name[this.language],
        };
      });
    },
    // placesList() {
    //   return this.placesRows
    // },
  },

  async created() {
    this.model = formSchema.initialValues(this.record);
  },
  // async mounted() {
  //   await this.doFetchCities()
  // },

  methods: {
    ...mapActions({
      doFind: 'plan/form/doFind',
      doNew: 'plan/form/doNew',
      doUpdate: 'plan/form/doUpdate',
      doCreate: 'plan/form/doCreate',
      doFetchCities: 'city/list/doFetch',
      doFetchPlaces: 'place/list/doFetch',
      doAddCreditToWallet:
        'payment/form/doAddCreditToWallet',
    }),

    async rechargeWallet() {
      const response = await this.doAddCreditToWallet(
        parseFloat(this.amount),
      );
      if (response) {
        this.$emit('close');
        this.$q.notify({
          message: this.i18n(
            'entities.payment.notifications.rechargeSuccess',
          ),
          color: 'green',
          position: 'top-right',
        });
      }
      else{
        this.$emit('close');
        this.$q.notify({
          message: this.i18n(
            'entities.payment.notifications.rechargeError',
          ),
          color: 'green',
          position: 'top-right',
        });
      }
      // window.location.replace(response.paymentPageUrl);
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.citiesOptions = this.cities;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.citiesOptions = this.cities.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1,
        );
      });
    },

    doClear() {
      this.selected_cities = [];
    },
    handleAddBranch() {
      console.log('SUBMITTED');
      const newAdmin = {
        firstName: this.firstName,
        lastName: this.lastName,
        branchCity: this.branchCity,
        phoneNumber: this.phoneNumber,
        emailAddress: this.emailAddress,
        createdAt: Date.now(),
      };
      this.values.push(newAdmin);
      console.log(newAdmin);
      // this.dialogVisible=false
      this.$emit('adminCreated');
    },
    validateAmount(inputtxt) {
      // const phoneno = /^\d{11}$/
      const isDigit = /^\d+$/;
      if (isDigit.test(inputtxt)) {
        // this.validPhone = true
        return true;
      } else {
        // this.validPhone = false
        return false;
      }
    },
    async doSubmit() {
      const { id, ...values } = formSchema.cast(this.model);
      values.numberOfDays = parseInt(
        this.model.numberOfDays,
      );
      values.programs = Object.keys(this.programList).map(
        (key) => {
          return {
            name: key,
            places: this.programList[key].map((item) => {
              return {
                id: item['id'],
                selectedImage: item['selectedImage']
                  ? item['selectedImage']
                  : '',
                averageTime: item['averageTime']
                  ? item['averageTime']
                  : 0,
              };
            }),
          };
        },
      );
      if (this.isEditing) {
        return await this.doUpdate({
          id,
          values,
        });
      } else {
        let record = await this.doCreate(values);
        return record;
      }
    },
  },
};
