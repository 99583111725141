//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import PaymentWalletModal from '@/modules/payment/components/payment-wallet-modal.vue';
import WithdrawRequestModal from '@/modules/payment/components/withdraw-request-modal.vue';
import PaymentWalletReportCard from '@/modules/payment/components/payment-wallet-report-card.vue';

import ReportBox from '@/modules/home/components/report-box.vue';


export default {
    name: 'app-payment-wallet-card',
    data(){
        return{
            modalVisible: false,
            dialogVisible: false,
            modalType: undefined,
            modalSuccessVisible: false,
            success: false,
        }
    },
    components:{
        [PaymentWalletModal.name]: PaymentWalletModal,
        [WithdrawRequestModal.name]: WithdrawRequestModal,
        [ReportBox.name]: ReportBox,
        [PaymentWalletReportCard.name]: PaymentWalletReportCard
    },
    methods:{
        ...mapActions({
            doFindWallet: 'payment/view/doFindWallet',
            doFindPendingRequest: 'payment/view/doFindPendingRequest'
        }),
        i18n(key, args) {
            return this.$t(key, args);
        },        
        onOpenModal(operation) {
            this.modalVisible = true;
            this.dialogVisible = true;
            this.modalType = operation
        },
        onModalClose() {
          this.dialogVisible = false;
          setTimeout(() => {
            this.modalType = undefined
            this.modalVisible = false;
          }, 200);
        },
        onModalSuccessClose() {
            console.log('CLOSED');
            this.success = false;
            setTimeout(() => {
                this.modalSuccessVisible = false;
            }, 200);
        },

        requestCreated() {
            this.onModalClose();
            this.success = true;
            this.modalSuccessVisible = true;
        },
    },
    computed:{
        ...mapGetters({
            currentUser: 'auth/currentUser',
            wallet: 'payment/view/wallet',
            decryptedBalance: 'payment/view/decryptedBalance',
            loading: 'payment/view/loading'
        })
    },
    async created(){
        await this.doFindWallet(this.currentUser.companyId)
        // await this.doFindPendingRequest(this.currentUser.companyId)
    }
}
