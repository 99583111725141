//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'report-box',
  props: {
    type: {
      type: String,
      required: true,
      default: 'line',
      validator: value => {
        return ['line', 'pie', 'doughnut', 'bar'].indexOf(value) !== -1
      }
    },
    label: {
      type: String,
      required: false,
      default: 'Report Title',
    },
    background: {
      type: String,
      required: false,
      // default: '#E3F8FA',
    },
    color: {
      type: String,
      required: false,
      // default: 'rgba(28, 28, 28, 0.8)',
    },
    up: {
      type: Boolean,
      default: false,
    },
    number: {
      type: String
    },
    category: {
      type: String,
    },
  },

  mounted() {
    this.id = this._uid
  },
  data() {
    return {
      id: null,
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    
    myStyle() {
      if (this.background !== null && this.color !== null) {
        return {
          backgroundColor: this.background,
          color: this.color, 
        }
      } else {
        return ''
      }
    },
  },

  methods: {
    ...mapActions({

    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
